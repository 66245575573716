export const common = {
  login: '立即登录',
  handle_success: '操作成功',
  handle_error: '操作失败',
  empty_data: '暂无数据',
  all: '全部',
  empty: '暂无',
  enable: '显示',
  disable: '禁用',
  finish: '完成',

  certification: '认证',

  agree: '同意',
  reject: '拒绝',
  yes: '是',
  no: '否',
  to: '至',
  omit: '略',
  show: '显示',
  hidden: '隐藏',
  confirm: '确定',
  cancel: '取消',
  return: '返回',
  next: '下一步',
  select: '选择',
  search: '查找',
  audio: '审核',
  upload: '点击上传',
  reset: '重置',
  more: '更多',
  create: '添加',
  update: '修改',
  view: '详情',
  audit: '审核',
  batch_audit: '批量审核',
  audit_finish: '审核完成',

  minute: '分钟',

  certification_status: '认证状态',
  pay_status: '支付状态',
  audit_status: '审核状态',
  order_status: '订单状态',

  wait_pass: '待审核',
  pass: '审核通过',
  no_pass: '审核拒绝',
  content: '审核备注',


  recommended: '已推荐',
  unrecommended: '未推荐',

  basic_info: '基本信息',
  detail_info: '详细信息',
  audit_info: '审核信息',
  certification_info: '认证信息',

  statistical: '统计',
  total: '总计',
  console: '控制台',
  refresh: '刷新',
  pack: '折叠',
  spread: '展开',
  spread_pack: '展开 | 折叠',
  refresh_data: '数据刷新',
  clear_cache: '清除缓存',
  message: '系统消息',
  permission: '权限',
  delete: '删除',
  batch_delete: '批量删除',
  export: '导出',
  import: '导入',
  template: '模板',
  startTime: '开始日期',
  endTime: '结束日期',
  logout: '退出',
  handle: '操作',
  for: '进行',
  prompt: '提示',
  to_determine_the: '确定对',
  please_select: '请选择',
  please_input: '请输入',
  finish_clear: '清空完成',
  user_center: '个人中心',
  province: '省',
  city: '市',
  region: '区/县',

  month: '月份',

  man: '男',
  woman: '女',
  secrecy: '保密',

  sms_notification: '短信通知',
  send_sms_notification: '是否向该用户发送短信通知',

  initialization_password: '密码初始化',
  confirm_initialization_password: '您确定要初始化当前用户的登录密码？',

  current_check: '当前选中的内容',
  batch_check: '批量选中的内容',

  picture_info: '图片',
  file_info: '文件',
  audio_info: '音频',
  video_info: '视频',

  back_prev_page: '返回上一页',
  enter_home: '进入首页',

  id: '序号',
  title: '标题',
  content: '内容',
  mobile: '联系电话',
  weixin: '微信',
  email: '电子邮件',
  area: '所在地区',
  sort: '排序',
  is_hot: '是否热门',
  is_recommend: '首页推荐',
  is_hidden: '是否隐藏',
  status: '数据状态',
  start_time: '开始时间',
  end_time: '结束时间',
  join_time: '加入时间',
  create_time: '创建时间',
  update_time: '更新时间',
  start_stop_time: '起止时间',
  default_password: '默认密码',
  no_data: '暂无数据',

  basic_info: '基本信息',
  detail_info: '详细信息',
  audit_info: '审核信息',
  certification_info: '认证信息',

  picture: {
    rules: {
      require: '图片不能为空',
      type: '图片只能是 JPG 或 PNG 格式',
      size: '图片大小不能超过 10MB',
    },
  },

  file: {
    rules: {
      require: '文件不能为空',
      type: '文件格式错误',
      size: '文件大小不能超过 50MB',
    },
  },
}
